import { mapActions, mapGetters } from "vuex";

export default {
    name: "platform",
    components: {},
    computed: {
        ...mapGetters({}),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions({}),
    },
};
